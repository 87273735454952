<template>
  <div @click="handleClick" :class="type" class="btn" :style="buttonstyle">{{buttonname}}</div>
</template>
<script>
export default {
  name: "btn",
  components: {},
  props: {
    //按钮公共样式
    type: String,
    //按钮名字
    buttonname: String,
    //按钮额外样式
    buttonstyle: Object,
  },
  methods: {
    //click事件抛出去
    handleClick () {
      this.$emit("click");
    },
  },
  created () {
  },
  computed: {},
  mounted () { },
  data () {
    return {};
  },
};
</script>
<style spcoed lang="scss">
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.primary {
  color: #fff;
  background-color: #2468f2;
  border-color: #2468f2;
}
.success {
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}
.danger {
  color: #fff;
  background-color: #f56c6c;
  border-color: #f56c6c;
}
</style>