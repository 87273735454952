<!--  -->
<template>
  <div>
    <div class="online">
      <div class="online_one">
        <div class="online_items" id="chat3" @click="openHandle">
          <span class="iconfont pdxleftfont-135"></span>
          <p>在线咨询</p>
        </div>
        <div class="online_items online_phone" @mouseover="overHandle(1)" @mouseout="outHandle(1)">
          <span class="iconfont pdx24gl-phoneLoudspeaker"></span>
          <p>电话咨询</p>
        </div>
        <div class="online_items" id="zixun" @mouseover="overHandle(2)" @mouseout="outHandle(2)">
          <span class="iconfont pdxerweima1"></span>
          <p>扫码关注</p>
        </div>
        <!-- <div class="online_items">
          <a @click.stop="reback('ly')">
            <span class="iconfont pdxyijianfankui"></span>
            <p>反馈意见</p>
          </a>
        </div>-->
        <div class="online_items" @click="jump('http://doc.icuapi.com')">
          <span class="iconfont pdxzaixianwendang"></span>
          <p>在线文档</p>
        </div>
      </div>

      <div class="connect_phone" v-if="display">
        <div class="top">
          <span>电话联系</span>
        </div>
        <div class="two">
          <span class="iconfont pdx24gl-phoneLoudspeaker call"></span>
          <div class="right">
            <p>欢迎致电，我们将为你提供帮助！</p>
            <span>0536 - 8184803</span>
          </div>
        </div>
        <div class="three">
          <div class="left">
            <div class="title">售前咨询</div>
            <p>
              <span>张经理：</span>15653459345
              <span></span>
            </p>
          </div>
          <div class="right">
            <img src="@/assets/img/ewm03.png" alt />
          </div>
        </div>
        <div class="three">
          <div class="left">
            <div class="title">售前咨询</div>
            <p>
              <span>孙经理：</span>13455656999
              <span></span>
            </p>
          </div>
          <div class="right">
            <img src="@/assets/img/ewm02.png" alt />
          </div>
        </div>
        <p class="same">注：手机微信同号</p>
      </div>
      <div class="wechat" v-if="display1">
        <div class="we_img">
          <img src="@/assets/img/ewm.png" alt />
        </div>
        <div class="title">扫码进入会员中心</div>
      </div>
    </div>
    <transition name="lc">
      <div class="toTop" v-if="dispaly2" @click="topHandle">
        <span class="iconfont pdxxiangshang"></span>
      </div>
    </transition>
  </div>
</template>

<script>

import mixin from '@/mixin'
export default {
  mixins: [mixin],
  data () {
    return {
      display: false,
      display1: false,
      dispaly2: false
    };
  },

  components: {},

  computed: {},
  mounted () {
    window.addEventListener('scroll', this.scrollHandle)
  },
  methods: {
    jump (url) {
      window.open(url)
    },
    reback (id) {
      if (localStorage.getItem('id')) {
        let select = localStorage.getItem('id')
        let ele = document.getElementById(select)
        if (select) {
          ele.scrollIntoView(true)
        }
      } else {
        let select = id
        localStorage.setItem('id', select)
        this.$router.push({ name: 'aboutUs' })
      }

    },

    scrollHandle () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 500) {
        this.dispaly2 = true
      } else[
        this.dispaly2 = false
      ]
    },
    overHandle (n) {
      if (n == 1) {
        this.display = true
      } else {
        this.display1 = true
      }

    },
    outHandle (n) {
      if (n == 1) {
        this.display = false
      } else {
        this.display1 = false
      }
    },
    openHandle () {
      // ?siteId=15666031&userId=30332628&siteToken=de58f1ae2c60149aebc3ae71bfdaf1f8
      var url = "http://p.qiao.baidu.com/cps/chat";
      var name = '在线资讯';
      var iHeight = 760;
      var iWidth = 700;
      var iTop = (window.screen.height - 30 - iHeight) / 2;
      var iLeft = (window.screen.width - 10 - iWidth) / 2;
      window.open(url, name, 'height=' + iHeight + ',,innerHeight=' + iHeight + ',width=' + iWidth +
        ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft +
        ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');
    },
    topHandle () {
      var top = 0
      if (document.documentElement) {
        top = document.documentElement
        // document.documentElement.scrollTop = 0
      } else {
        top = document.body
      }
      let timer = setInterval(() => {
        if (top.scrollTop <= 0) {
          clearInterval(timer)
        } else {
          top.scrollTop -= 100
        }
      }, .1)

    }
  }
}

</script>
<style lang='scss' scoped>
.online {
  position: fixed;
  bottom: 240px;
  right: 0;
  z-index: 1000;
}

.online .online_one {
  width: 80px;
  height: auto;
  background: #2468f2;
  box-shadow: 0 0 10px rgba(41, 61, 192, 0.2);
  border-radius: 2px 0 0 2px;
}

.online_items {
  text-align: center;
  cursor: pointer;
  color: #fff;
  padding: 12px 0;
  border-bottom: 1px solid #efeff9;
  margin: 0 10px;
}

.online_items:last-child {
  border: 0 !important;
}

.online_items .iconfont {
  font-size: 20px;
  color: #fff;
}

.online_items p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: 1px;
  padding-top: 7px;
}
.toTop {
  width: 80px;
  height: 80px;
  text-align: center;
  background: #2468f2;
  color: #fff;
  // font-size: 45px;
  /* margin-top: 10px; */
  line-height: 80px;
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 150px;
  z-index: 10000;
  border-radius: 2px 0 0 2px;
  box-shadow: 0 0 10px rgba(41, 61, 192, 0.2);
}

.online .wechat {
  width: 212px;
  height: 242px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(41, 61, 192, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 88px;
  bottom: 218px;
  z-index: 1000;
  border-radius: 2px;
}

.online .wechat .title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #3a3434;
  text-align: center;
}
.we_img {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  /* padding-top: 34px; */
  padding-bottom: 10px;
}

.we_img img {
  width: 100%;
  height: 100%;
}
.connect_phone {
  position: fixed;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(41, 61, 192, 0.2);
  bottom: 300px;
  right: 86px;
  padding: 20px 28px;
  border-radius: 2px;
}

.connect_phone.show {
  display: block;
}
.top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #e1e1e5;
  margin-bottom: 17px;
}

.top span {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #3a3434;
}

.two {
  display: flex;
  justify-content: space-between;
}
.two .call {
  font-size: 50px;
  color: #1573f0;
}
.two .right p {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #3a3434;
  margin-bottom: 10px;
}

.two .right span {
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  color: #3a3434;
}

.three {
  width: 248px;
  display: flex;
  justify-content: space-between;
  background: #efefef;
  padding: 10px;
  padding: 10px;
  margin-top: 20px;
}

.three .left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.three .left .title {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  color: #3a3434;
}

.three .left p span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #818181;
}

.three .right {
  width: 75px;
  height: 75px;
}

.three .right img {
  width: 100%;
  height: 100%;
}

.same {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #818181;
  text-align: right;
  margin-top: 14px;
}
.lc-enter,
.lc-leave-to {
  //进入前，离开后
  opacity: 0;
}
.lc-enter-to,
.lc-leave {
  //进入后，离开前
  opacity: 1;
}
.lc-enter-active,
.lc-leave-active {
  transition: all 0.5s;
}
</style>
