import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//引入初始化样式文件
import "normalize.css";
//引入公共组件
import btn from "@/components/common/btn";
Vue.component('btn', btn)
import pagination from "@/components/common/pagination/pagination";
Vue.component('pagination', pagination)
    // 引入jq
import $ from 'jquery'
Vue.prototype.$ = $
    // 设置axios的默认baseUrl
Vue.config.productionTip = false;
Vue.use(ElementUI)
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");