import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: "" || localStorage.getItem('token')
    },
    mutations: {
        saveToken(state, val) {
            state.token = val
            localStorage.setItem("token", val)
        }
    },
    actions: {

    },
    modules: {},
});