import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [{
        path: "/",
        component: () =>
            import ("../views/module/front/Home.vue"),
        children: [{
                path: '/',
                name: "home",
                component: () =>
                    import ('../views/module/front/home/index')
            },
            {
                path: '/Interface/list',
                name: "api",
                component: () =>
                    import ('../views/module/front/Interface/list')
            },
            {
                path: '/product/info',
                name: "apiInfo",
                component: () =>
                    import ('../views/module/front/product/info')
            },
            {
                path: '/purchase/index',
                name: "purchase",
                component: () =>
                    import ('../views/module/front/purchase/index')
            },
            {
                path: '/tools/index',
                name: "tools",
                component: () =>
                    import ('../views/module/front/tools/index')
            }

        ]
    },
    {
        path: '/login',
        name: "login",
        component: () =>
            import ('../views/module/login')
    },
    {
        path: '/agress/protection',
        name: "protection",
        component: () =>
            import ('../views/module/front/agress/protection')
    },
    {
        path: '/agress/service',
        name: "service",
        component: () =>
            import ('../views/module/front/agress/service')
    },

];

const router = new VueRouter({
    mode: "history",
    routes,
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default router;