<template>
  <div id="app">
    <router-view />
    <online v-if="$route.path!='/agress/protection'&&$route.path!='/agress/service'"></online>
  </div>
</template>
<script>
import online from '@/components/pc/p-online/p-online'
export default {
  name: "app",
  components: {
    online
  },
  created () {
    // if (this.$store.state.token) {
    //   this.$store.dispatch("initRouter")
    // } else {
    //   console.log(111);
    //   // this.$router.replace({ name: "login" })
    // }
  },
}
</script>
<style lang="scss">
</style>
